import './App.css';
import './rete.css';

import { ModuleList } from './components/editorModules/ModuleList';

function App(): JSX.Element {
    return (
        <div className='editor'>
            <ModuleList />
        </div>
    );
}

export default App;