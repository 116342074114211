import { useCallback, useState } from 'react';

// material-ui
import Box from '@mui/material/Box';
import Tooltip from '@mui/material/Tooltip';

// project import
import { IconButton } from '@mui/material';
import { useTranslation } from 'react-i18next';

// assets
import { Fullscreen, FullscreenExit } from '@mui/icons-material/';

// ==============================|| HEADER CONTENT - FULLSCREEN ||============================== //

export const FullScreen = () => {
    const { t } = useTranslation();
    const [open, setOpen] = useState(false);

    const handleToggle = useCallback(() => {
        setOpen((prevOpen) => !prevOpen);
        const doc = document as any;
        const docEl = doc.documentElement;

        if (!doc.fullscreenElement && !doc.webkitFullscreenElement && !doc.mozFullScreenElement && !doc.msFullscreenElement) {
            if (docEl.requestFullscreen) {
                docEl.requestFullscreen();
            } else if (docEl.mozRequestFullScreen) { /* Firefox */
                docEl.mozRequestFullScreen();
            } else if (docEl.webkitRequestFullscreen) { /* Chrome, Safari & Opera */
                docEl.webkitRequestFullscreen();
            } else if (docEl.msRequestFullscreen) { /* IE/Edge */
                docEl.msRequestFullscreen();
            }
        } else {
            if (doc.exitFullscreen) {
                doc.exitFullscreen();
            } else if (doc.mozCancelFullScreen) { /* Firefox */
                doc.mozCancelFullScreen();
            } else if (doc.webkitExitFullscreen) { /* Chrome, Safari & Opera */
                doc.webkitExitFullscreen();
            } else if (doc.msExitFullscreen) { /* IE/Edge */
                doc.msExitFullscreen();
            }
        }
    }, []);

    return (
        <Box sx={{ flexShrink: 0, ml: 0.75 }}>
            <Tooltip title={open ? t('Exit Fullscreen') : t('Fullscreen')}>
                <IconButton
                    size='small'
                    id='full_screen'
                    aria-label="Fullscreen"
                    onClick={handleToggle}
                    sx={{ ml: 1 }}
                    color='primary'
                >
                    {open ? <FullscreenExit /> : <Fullscreen />}
                </IconButton>
            </Tooltip>
        </Box>
    );
};
