import React, { useEffect, useRef } from 'react';

import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    TextField,
    Button,
    Box,
    Alert,
} from '@mui/material';
import { Cancel, Save, WarningAmber } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { Module } from '../../rete/types';
import useLocalStorage from '../../hooks/useLocalStorage';
import { slugify_text } from '../../util/util';

type NewModuleModalProps = {
    open: boolean;
    onClose: () => void;
    save: (moduleName: string) => void;
};

export const NewModuleModal = (props: NewModuleModalProps): JSX.Element => {
    const [moduleName, setModuleName] = React.useState('');
    const [formErrorVisible, setFormErrorVisible] = React.useState(false);
    const [formErrorMessage, setFormErrorMessage] = React.useState('');
    const [modules] = useLocalStorage<Module[]>('modules', []);

    const { t } = useTranslation();

    const descriptionElementRef = useRef<HTMLElement>(null);
    useEffect(() => {
        if (props.open) {
            const { current: descriptionElement } = descriptionElementRef;
            if (descriptionElement !== null) {
                descriptionElement.focus();
            } else {
                setTimeout(() => {
                    const dialog = document.getElementById('module-name');
                    if (dialog) {
                        dialog.focus();
                    } else {
                        console.warn('El elemento con ID "module-name" no está presente en el DOM.');
                    }
                }, 0);
            }
        }
    }, [props.open]);

    const showErrorMessage = (message: string): void => {
        setFormErrorVisible(true);
        setFormErrorMessage(message);
        setTimeout(() => setFormErrorVisible(false), 5000);
    };

    const saveModule = (): void => {
        if (moduleName.length > 15) {
            showErrorMessage(t('new_module_modal.module_name_max_length'));
            return;
        }else if (modules.find((module: Module) => slugify_text(module.label) === slugify_text(moduleName))) {
            showErrorMessage(t('new_module_modal.module_name_exists'));
            return;
        }
        props.save(moduleName);
        setModuleName('');
    };

    return(
        <Dialog
            open={props.open}
            onClose={props.onClose}
            scroll={'paper'}
            PaperProps={{
                component: 'form',
                onSubmit: (event: React.FormEvent<HTMLFormElement>) => {
                    event.preventDefault();
                    saveModule();
                },
            }}
            aria-labelledby="scroll-dialog-title"
            aria-describedby="scroll-dialog-description"
            fullWidth={true}
            maxWidth='sm'
        >
            <DialogTitle id="scroll-dialog-title" textAlign={'center'} variant='h6' gutterBottom>
                {t('new_module_modal.new_module')}
                <span className='close' onClick={props.onClose}>
                    &times;
                </span>
            </DialogTitle>
            <DialogContent dividers={true}>
                <Box
                    id="scroll-dialog-description"
                    ref={descriptionElementRef}
                    tabIndex={-1}
                >
                    <TextField
                        label={t('new_module_modal.module_name_placeholder')}
                        id='module-name'
                        value={moduleName}
                        required
                        onChange={(e): void => setModuleName(e.target.value)}
                        sx={{ m: 1, width: '98.5%'}}
                        size="small"
                        autoFocus={true}
                    />
                    <Box className={'mt-16'} sx={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
                        {formErrorVisible &&
                            <Alert sx={{ m: 1, width: '100%' }} severity="error" icon={<WarningAmber />}>
                                {formErrorMessage}
                            </Alert>
                        }
                    </Box>
                </Box>
            </DialogContent>
            <DialogActions>
                <Button variant="outlined" startIcon={<Cancel />} onClick={props.onClose}>{t('new_module_modal.cancel')}</Button>
                <Button type="submit" variant="contained" startIcon={<Save />}>{t('new_module_modal.create')}</Button>
            </DialogActions>
        </Dialog>
    );
};